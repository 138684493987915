import React from 'react';
import Slider from 'react-slick';
import './slick.css';
import './slick-theme.css';
import styles from './styles.module.scss';

const View = (props) => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 5,
    slidesToScroll: 5,
    arrows: true, // Näytä ohjausnuolet
    responsive: [
      {
        breakpoint: 1024, // Näytöt alle 1024px
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          arrows: true,
        },
      },
      {
        breakpoint: 768, // Näytöt alle 768px
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
          arrows: true, // Ei nuolia pienillä näytöillä
        },
      },
      {
        breakpoint: 480, // Näytöt alle 480px
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          dots: true,
          arrows: true,
        },
      },
    ],
  };

  const { channels = [] } = props.data;

  return (
    <div className="view-channels-block">
      <h2>Kanavia</h2>
      <div className="slider-container">
        <Slider {...settings}>
          {channels.map((channel, index) => (
            <div className="Channel" key={channel.id || index}>
              <a
                key={channel.id || index}
                href={channel.link || '#'} // Linkki kanavalle, oletuksena '#'
                target="_blank" // Avataan linkki uuteen välilehteen
                rel="noopener noreferrer" // Turvallinen linkitys
                className={styles.channelLink} // Käytä samaa tyyliä
                style={{ textDecoration: 'none' }} // Poista oletustekstityylit linkiltä
              >
                <div
                  className="Channel-content"
                  style={{ backgroundColor: channel.color }}
                >
                  <div
                    className={`${styles.customIcon} ${
                      channel.icon || 'default-icon'
                    }`}
                    style={{
                      width: '115px',
                      height: '115px',
                      marginTop: 'auto',
                      marginBottom: 'auto',
                    }}
                  />
                  <p>{channel.name}</p>
                </div>
              </a>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default View;
