/**
 * A Block View that wraps VideoJS Block View and adds some mediapage specific functionalities.
 * @module components/manage/Blocks/MediaPageVideoJS
 */

import { useUIDVariablesPoll } from 'volto-epics-addon/helpers';
import React, { useEffect, useState, useRef } from 'react';
import { Progress, Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Body from '../VideoJS/Body';
import { withBlockExtensions } from '@plone/volto/helpers';
import cx from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { getContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import config from '@plone/volto/registry';
import mime from 'mime-types';
import { MEDIA_PAGE_WOWZA_ROUTE } from '@package/constants';
import { useWorkflowStatePolling } from '@package/helpers/WorkflowHook/WorkflowHook';
import { Api } from '@plone/volto/helpers';

// These are the profiles that the block will try query about
// (they also define the order in which the elements will be
// rendered)
const profiles = ['Audio', '360p', '720p', '1080p', '2160p'];

// This probably should not be the master of this information
const titles = {
  Audio: 'Audio only',
  '360p': '360p',
  '720p': '720p',
  '1080p': '1080p',
  '2160p': '2160p',
};

const keyFromItem = (item) => {
  if (item['@type'] === 'transcoding') {
    return item['title'];
  }
  if (item['@type'] === 'subtitle') {
    try {
      const urlParts = item['@id'].split('/');
      const id = urlParts[urlParts.length - 1];
      return id.split('-')[0];
    } catch {}
  }
  return null;
};

const View = (props) => {
  const dispatch = useDispatch();

  const { token } = useSelector((state) => state.userSession);
  const api = new Api();

  const { data, className } = props;

  const content = useSelector((state) => state.content.data);

  const [playerData, setPlayerData] = useState(null);
  const [playerInitialized, setPlayerInitialized] = useState('WAITING');

  const [transcodingProgress, setTranscodingProgress] = useState(null);
  const [transcriptionProgress, setTranscriptionProgress] = useState(null);

  const [completedItems, setCompletedItems] = useState([]);
  const [failedItems, setFailedItems] = useState([]);

  const [progressShouldPoll, setProgressShouldPoll] = useState(true);
  const progressVariables = useUIDVariablesPoll(
    5000,
    content.UID,
    ['transcodingProgress', 'transcriptionProgress', 'duration'],
    progressShouldPoll,
  );

  const itemsRef = useRef(content.items);

  // Poll workflowState as long as there are in_progress items
  const workflowState = useWorkflowStatePolling(content.items, keyFromItem);

  // Sets a ref for async functions
  useEffect(() => {
    itemsRef.current = content.items;
  }, [content.items]);

  // Hopefully get up-to-date content subitems after a small delay
  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(getContent(flattenToAppURL(content['@id'])));
    }, 3000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // Update completedItems and failedItems from workflow information
  useEffect(() => {
    const newCompletedItems = [];
    const newFailedItems = [];

    for (const key of Object.keys(workflowState)) {
      const state = workflowState[key]?.state;
      if (state === 'completed') {
        newCompletedItems.push(key);
      }
      if (state === 'failed') {
        newFailedItems.push(key);
      }
    }

    if (newCompletedItems.length !== completedItems.length) {
      setCompletedItems(newCompletedItems);
    }
    if (newFailedItems.length !== failedItems.length) {
      setFailedItems(newFailedItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workflowState, setCompletedItems, setFailedItems]);

  // Check if the progress polling can be stopped
  useEffect(() => {
    const progressItems = itemsRef.current.filter(
      (item) => item['@type'] === 'transcoding' || item['@type'] === 'subtitle',
    );
    if (completedItems.length + failedItems.length === progressItems.length) {
      setProgressShouldPoll(false);
    } else {
      setProgressShouldPoll(true);
    }
  }, [completedItems, failedItems]);

  // Initializes the player when page is (re)load.
  useEffect(() => {
    let controller = new AbortController();
    (async () => {
      // Initialize only if smi-id has been set.
      if (content['s3_object_id'] !== null && content['s3_object_id'] !== '') {
        try {
          // Prepare data for the player
          const tempData = {};
          if (content.items.length) {
            const subtitles = [];
            // Add subtitles based on content objects under the media page
            for (const item of content.items) {
              if (item['@type'] === 'subtitle') {
                // Only take subtitles marked as completed
                const workflow = await api.get(
                  flattenToAppURL(item['@id']) + '/@workflow',
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  },
                );
                const state =
                  workflow?.['chain']?.['media_output_workflow']?.['state']?.[
                    'id'
                  ];
                if (state && state !== 'completed') {
                  continue;
                }
                subtitles.push([
                  {
                    '@id': item['url'],
                    title: item['title'],
                    language: item['title'],
                  },
                ]);
              }
            }
            if (subtitles.length) {
              tempData.subtitles = subtitles;
            }
          }

          // Get the stream URL
          const resp = await fetch(
            `${config.settings.publicPath}/${MEDIA_PAGE_WOWZA_ROUTE}/${content['UID']}/${content['s3_object_id']}`,
            {
              signal: controller.signal,
            },
          );
          const jsonData = await resp.json();

          // And update the player.
          if (jsonData['url']) {
            tempData.sources = [
              {
                src: jsonData['url'],
                type: mime.lookup(jsonData['url']),
              },
            ];
            setPlayerData({
              ...data,
              options: {
                ...data.options,
                ...tempData,
              },
            });
          }
        } catch (e) {
          setPlayerInitialized('FAILED');
          // eslint-disable-next-line no-console
          console.log(e);
        }

        // If happily here, say that we are initialized.
        setPlayerInitialized('INITIALIZED');
      } else {
        setPlayerInitialized('NOT_INITIALIZED');
      }
    })();
    return () => {
      controller?.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // At some specific completions, reload the page.
  useEffect(() => {
    // Only refresh things if the player is not initialized
    if (playerInitialized === 'NOT_INITIALIZED') {
      const transcodingItems = itemsRef.current
        .filter((item) => item['@type'] === 'transcoding')
        .map((item) => item['title']);

      // If only audio transcoding present and audio is completed, refresh
      if (
        !transcodingItems.includes('360p') &&
        completedItems.includes('Audio')
      ) {
        window.location.reload();
        return;
      }

      // If 360p has been completed, reload.
      if (completedItems.includes('360p')) {
        window.location.reload();
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerInitialized, completedItems]);

  useEffect(() => {
    if (progressVariables != null) {
      const transcodingProgressRaw = {};

      for (const key in progressVariables) {
        if (progressVariables.hasOwnProperty(key)) {
          if (
            Object.keys(progressVariables[key]).includes('transcodingProgress')
          ) {
            const transcodingProgress =
              progressVariables[key].transcodingProgress;
            const progressObject = JSON.parse(transcodingProgress);
            for (const profile in progressObject) {
              if (progressObject.hasOwnProperty(profile)) {
                const currentTime = progressObject[profile].current_time;
                transcodingProgressRaw[profile] = { current_time: currentTime };
              }
            }
          }
        }
      }

      const duration =
        progressVariables[Object.keys(progressVariables)[0]].duration;

      const newTranscodingProgress = {};
      Object.keys(transcodingProgressRaw).forEach((key) => {
        const current = parseFloat(transcodingProgressRaw[key]['current_time']);
        if (!(completedItems.includes(key) || failedItems.includes(key))) {
          newTranscodingProgress[key] = Math.round((current / duration) * 100);
        }
      });
      setTranscodingProgress(newTranscodingProgress);
    } else {
      setTranscodingProgress(null);
    }
  }, [progressVariables, completedItems, failedItems, setTranscodingProgress]);

  useEffect(() => {
    if (progressVariables != null) {
      const flattened = Object.assign([], ...Object.values(progressVariables));

      const transcriptionProgressRaw = JSON.parse(
        flattened['transcriptionProgress'] ?? '{}',
      );

      const newTranscriptionProgress = {};
      Object.keys(transcriptionProgressRaw).forEach((key) => {
        if (!(completedItems.includes(key) || failedItems.includes(key))) {
          newTranscriptionProgress[key] = {
            ...transcriptionProgressRaw[key],
          };
        }
      });
      setTranscriptionProgress(newTranscriptionProgress);
    } else {
      setTranscriptionProgress(null);
    }
  }, [
    progressVariables,
    completedItems,
    failedItems,
    setTranscriptionProgress,
  ]);

  return (
    <>
      {playerData ? (
        <div
          className={cx(
            'block video align',
            {
              center: !Boolean(data.align),
            },
            data.align,
            className,
          )}
        >
          <Body
            {...props}
            parentUid={props.properties?.UID}
            parentType={props.properties?.['@type']}
            parentItems={props.properties?.items}
            data={playerData}
            editMode={false}
          />
        </div>
      ) : null}
      {/* Show throbber if no player or no processing bars (for transcoding) */}
      {((!transcodingProgress ||
        Object.keys(transcodingProgress).length === 0) &&
        playerInitialized === 'NOT_INITIALIZED') ||
      playerInitialized === 'WAITING' ? (
        <div
          className="mediapage-before-progress"
          style={{ textAlign: 'center' }}
        >
          <Loader active inline />
        </div>
      ) : null}
      {/* Transription progress */}
      {transcriptionProgress?.['transcribe'] ? (
        <div className="mediapage-transcription-progress-indicator">
          <div key={'transcribe'}>
            {transcriptionProgress['transcribe']['detectedLanguage'] ? (
              <div>
                Subtitles language:{' '}
                {transcriptionProgress['transcribe']['detectedLanguage']}
              </div>
            ) : (
              <div>Subtitles processing ...</div>
            )}
            <Progress
              percent={transcriptionProgress['transcribe']['percentage']}
              indicating
              progress
            />
          </div>
        </div>
      ) : null}
      {/* Translation progress */}
      {transcriptionProgress?.['translate'] ? (
        <div className="mediapage-transcription-progress-indicator">
          <div key={'translate'}>
            <div>Subtitles translation to English ...</div>
            <Progress
              percent={transcriptionProgress['translate']['percentage']}
              indicating
              progress
            />
          </div>
        </div>
      ) : null}
      {/* Transcoding progress */}
      <div className="mediapage-transcoding-progress-indicator">
        {profiles.map((profile) =>
          transcodingProgress?.[profile] ? (
            <div key={profile}>
              <div> {titles[profile]} </div>
              <Progress
                percent={transcodingProgress[profile]}
                indicating
                progress
              />
            </div>
          ) : null,
        )}
      </div>
    </>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
View.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default withBlockExtensions(View);
