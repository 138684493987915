import React, { useState } from 'react';
import { DndContext, closestCenter } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Dropdown } from 'semantic-ui-react';
import styles from './styles.module.scss';

const DraggableChannel = ({
  channel,
  index,
  updateChannel,
  updateChannelColor,
  updateChannelIcon,
  updateChannelLink,
  removeChannel,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: index });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    backgroundColor: channel.color,
    padding: '10px',
    margin: '10px',
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  };

  const iconOptions = [
    { key: 'hat', value: 'hat-icon', text: 'Hat' },
    { key: 'jar', value: 'jar-icon', text: 'Jar' },
    { key: 'music', value: 'music-icon', text: 'Music' },
    { key: 'podcast', value: 'podcast-icon', text: 'Podcast' },
    { key: 'running', value: 'running-icon', text: 'Running' },
  ];

  const stopDragPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes}>
      <button {...listeners} style={{ cursor: 'grab', marginBottom: '10px' }}>
        Raahaa
      </button>
      {channel.icon && (
        <div
          className={`${styles.customIcon} ${channel.icon}`}
          style={{ width: '50px', height: '50px', marginBottom: '10px' }}
        ></div>
      )}
      <input
        type="text"
        value={channel.name}
        onChange={(e) => updateChannel(index, e.target.value)}
        onMouseDown={stopDragPropagation}
        placeholder="Kanavan nimi"
        style={{ marginBottom: '10px', width: '100%' }}
      />
      <input
        type="color"
        value={channel.color}
        onChange={(e) => updateChannelColor(index, e.target.value)}
        onMouseDown={stopDragPropagation}
        style={{ marginBottom: '10px', width: '100%' }}
      />
      <Dropdown
        placeholder="Valitse ikoni"
        selection
        options={iconOptions}
        value={channel.icon}
        onChange={(e, { value }) => updateChannelIcon(index, value)}
        onMouseDown={stopDragPropagation}
        style={{ marginBottom: '10px', width: '100%' }}
      />
      <input
        type="text"
        value={channel.link || ''}
        onChange={(e) => updateChannelLink(index, e.target.value)}
        onMouseDown={stopDragPropagation}
        placeholder="Lisää linkki"
        style={{ marginBottom: '10px', width: '100%' }}
      />
      <button
        onClick={(e) => {
          e.stopPropagation();
          removeChannel(index);
        }}
        style={{ marginTop: '10px' }}
      >
        Poista
      </button>
    </div>
  );
};

const Edit = ({ data, block, onChangeBlock }) => {
  const [channels, setChannels] = useState(data.channels || []);

  const updateChannel = (index, name) => {
    const updatedChannels = [...channels];
    updatedChannels[index].name = name;
    setChannels(updatedChannels);
    onChangeBlock(block, { ...data, channels: updatedChannels });
  };

  const updateChannelColor = (index, color) => {
    const updatedChannels = [...channels];
    updatedChannels[index].color = color;
    setChannels(updatedChannels);
    onChangeBlock(block, { ...data, channels: updatedChannels });
  };

  const updateChannelIcon = (index, icon) => {
    const updatedChannels = [...channels];
    updatedChannels[index].icon = icon;
    setChannels(updatedChannels);
    onChangeBlock(block, { ...data, channels: updatedChannels });
  };

  const updateChannelLink = (index, link) => {
    const updatedChannels = [...channels];
    updatedChannels[index].link = link;
    setChannels(updatedChannels);
    onChangeBlock(block, { ...data, channels: updatedChannels });
  };

  const removeChannel = (index) => {
    const updatedChannels = channels.filter((_, i) => i !== index);
    setChannels(updatedChannels);
    onChangeBlock(block, { ...data, channels: updatedChannels });
  };

  const addChannel = () => {
    const newChannels = [
      ...channels,
      { name: '', color: '#CCCCCC', icon: '', link: '' },
    ];
    setChannels(newChannels);
    onChangeBlock(block, { ...data, channels: newChannels });
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active && over && active.id !== over.id) {
      setChannels((channels) => {
        const oldIndex = parseInt(active.id, 10);
        const newIndex = parseInt(over.id, 10);
        const updatedChannels = arrayMove(channels, oldIndex, newIndex);
        onChangeBlock(block, { ...data, channels: updatedChannels });
        return updatedChannels;
      });
    }
  };

  return (
    <div>
      <h4>Muokkaa kanavia (Drag and Drop)</h4>
      <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext
          items={channels.map((_, index) => index)}
          strategy={horizontalListSortingStrategy}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              overflowX: 'auto',
            }}
          >
            {channels.map((channel, index) => (
              <DraggableChannel
                key={index}
                channel={channel}
                index={index}
                updateChannel={updateChannel}
                updateChannelColor={updateChannelColor}
                updateChannelIcon={updateChannelIcon}
                updateChannelLink={updateChannelLink}
                removeChannel={removeChannel}
              />
            ))}
          </div>
        </SortableContext>
      </DndContext>
      <button onClick={addChannel} style={{ marginTop: '10px' }}>
        Lisää kanava
      </button>
    </div>
  );
};

export default Edit;
